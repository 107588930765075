'use strict';

(function($){
	const body = document.getElementsByTagName('body')[0]
	var app = {
		init: function() {
			this.cookiesAccept();
			this.cookiesConfig();
			this.lazyframe();
			this.nav();
      		this.scrollingNav();
			this.slickHome();
			this.slickArticle();
			this.parallax();
		  	this.article();
		  	this.homeVideo();
		  	this.pushDataLayer();
		  	this.searcher();
		  	this.socialLink();
		  	this.copyLink();
		  	this.carouselRelatedArticles();
		  	this.cookiebotAcept();
			return false;
		},
		cookiesAccept: function () {
			var msgCookies = $('#cookies-msg');
			var btnAccept = $('.btn-cookies-accept');
			btnAccept.click(function (e){
				e.preventDefault();
				$.cookie(keyAnalyticCookies, '1', { expires: 7, path: '/' });
				$.cookie(keyMarketingCookies, '1', { expires: 7, path: '/' });
				$.cookie(keyRrssCookies, '1', { expires: 7, path: '/' });
				msgCookies.slideUp();
			})
		},
		cookiesConfig: function (){
			var configContainer = $('.cookies-configuration-block');
			var msgCookies = $('#cookies-msg');

			if(typeof configContainer.html() !== 'undefined')
			{
				var checkAll = configContainer.find('input#cookies-todas');
				var checkAnalytics = configContainer.find('input#cookies-analiticas');
				var checkMarketing = configContainer.find('input#cookies-marketing');
				var checkRrss = configContainer.find('input#cookies-rrss');
				var buttonSave = configContainer.find('.btn-save-config');
				var msgSuccess = configContainer.find('#text-success');
				var msgError = configContainer.find('#text-error');

				checkAll.click(function ()
				{
					if($(this).prop('checked'))
					{
						checkAnalytics.prop('checked',true);
						checkMarketing.prop('checked',true);
						checkRrss.prop('checked',true);
					}
					else
					{
						checkAnalytics.prop('checked',false);
						checkMarketing.prop('checked',false);
						checkRrss.prop('checked',false);
					}
				})
				buttonSave.click(function ()
				{
					msgSuccess.addClass('d-none');
					msgError.addClass('d-none');
					$.cookie(keyAnalyticCookies, ((checkAnalytics.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
					$.cookie(keyMarketingCookies, ((checkMarketing.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
					$.cookie(keyRrssCookies, ((checkRrss.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
					if(typeof msgCookies.html() !== 'undefined') msgCookies.hide();
					msgSuccess.removeClass('d-none');
				})
			}
		},
		lazyframe: function () {
			lazyframe('.lazyframe', {
				apikey: 'AIzaSyBG22hzTpV5lxNFeYgNDuv1SSD_NBEAu9k', // cambiar esta API por la del proyecto https://github.com/vb/lazyframe#apikey
				debounce: 250,

				onAppend: (function (lazyframe) {
					return console.log(lazyframe.parentNode.classList.add("loaded"))
				})
			});
		},
		nav: function() {
		
			const btnNav = document.getElementsByClassName('btn-burguer')[0],
			navOverlray = document.querySelector('.nav-overlay')
		
			btnNav.addEventListener('click', function () {
				body.classList.toggle('menu-active')
			}, false)

			document.addEventListener('keyup', function(event) {
				if (event.key === 'Escape') {
					if ( body.classList.contains('menu-active') ) {
						body.classList.remove('menu-active')
					}
				}
			})

			navOverlray.addEventListener('click', function () {
				body.classList.remove('menu-active')
			}, false)

		},
    scrollingNav : function ()
    {
      let navbar = document.getElementsByTagName('header')[0];
      
      if(getCurrentScroll > 50) {
        navbar.classList.add('header--scroll');
      }

      window.addEventListener('scroll', function() {
        
        let currentScrollPos = getCurrentScroll();
    
        window.requestAnimationFrame(function() {
    
          if (currentScrollPos < 50) {
            navbar.classList.remove('header--scroll');
          } else {
            navbar.classList.add('header--scroll');
          }

        });
      
      });
      
      function getCurrentScroll() {
        return window.pageYOffset || document.documentElement.scrollTop;
      };
      
    },
		sliderAutoplay : function (carousel,forceStop)
		{
			var current = carousel.find('.slick-current');
			if(current.find('video').html() === undefined && current.find('iframe').html() === undefined && !forceStop)
			{
				carousel.slick('slickPlay')
			}
			else
			{
				carousel.slick('slickPause')
			}
		},
		playPauseVideo : function(slick,control, carousel)
		{
			var current = carousel.find('.slick-current');
			var video = current.find('video');
			var iframe = current.find('iframe');
			if(video.html() !== undefined)
			{
				if (control === "play" && !carousel.hasClass('carousel-mobile')) {
					video.get(0).play();
					video.on('ended', function() {
						carousel.slick('slickNext')
					})
				} else {
					video.get(0).pause();
				}
			}
			if(iframe.html() !== undefined){
				if (control === "play" && !carousel.hasClass('carousel-mobile')) {
					iframe.get(0).contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
				} else {
					iframe.get(0).contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
				}
			}
		},
		slickHome: function() {
			jQuery(document).ready(function($) {
				var carousel = $('.slider-home');

				carousel.on("init", function(event, slick) {
					setTimeout(function() {
						app.sliderAutoplay(carousel);
						app.playPauseVideo(slick,'play', carousel);
					}, 1000);
					if(parseInt($.cookie(keyAnalyticCookies)) === 1 && typeof dataLayer !== 'undefined') {
						$('.slick-prev.slick-arrow').on('click', function () {
							dataLayer.push({
								'event': 'click_slide_show',
								'click_direction': 'left'
							});
						});
						$('.slick-next.slick-arrow').on('click', function () {
							dataLayer.push({
								'event': 'click_slide_show',
								'click_direction': 'right'
							});
						});
						$('.slick-dots').find('button').on('click', function (e) {
							let index = parseInt($(this).html()) - 1;
							let active = $('.slick-current').data('slick-index');
							dataLayer.push({
								'event': 'click_slide_show',
								'click_direction': (index > active) ? 'right' : 'left'
							});
						})
					}
				});
				carousel.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
					app.playPauseVideo(slick,'pause', carousel);
				});
				carousel.on("afterChange", function(event, slick, currentSlide) {
					app.sliderAutoplay(carousel);
					app.playPauseVideo(slick,'play', carousel);
				});
				carousel.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					fade: true,
					infinite: true,
					dots: true,
					rows: 0,
					lazyload: "ondemand",
					speed: 500,
					autoplaySpeed: 10000
				});
			});
		},
		slickArticle: function() {
			jQuery(document).ready(function($) {
				var carousel = $('.slider-article')

				carousel.on("init", function(event, slick) {
					setTimeout(function() {
						app.sliderAutoplay(carousel);
						app.playPauseVideo(slick,'play', carousel);
					}, 1000);

				});
				carousel.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
					app.playPauseVideo(slick,'pause', carousel);
				});
				carousel.on("afterChange", function(event, slick, currentSlide) {
					app.sliderAutoplay(carousel);
					app.playPauseVideo(slick,'play', carousel);
				});

				carousel.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: false,
					dots: true,
					rows: 0,
					lazyload: "ondemand",
					speed: 500,
					autoplaySpeed: 10000
				});
			});
		},
		parallax: function() {
			var images = document.querySelectorAll('.parallax');
			new simpleParallax(images, {
				delay: .6,
				transition: 'cubic-bezier(0,0,0,1)'
			});
		},
    article: function() {
      const btnArticle = document.querySelector('.article-search-btn')

      if(btnArticle) {
        btnArticle.addEventListener('click', function () {
          body.classList.toggle('article-active')
        }, false)
      }

    },
    homeVideo: function() {
      let videoContainer = document.getElementsByClassName("video-container")[0]
      let btnVideoPlay = $('.btn-play-video')
      let btnVideoClose = $('.btn-close-video')
	  let carousel = $('.slider-home')
      
      function embed(video_id) {
        let iframe = '<iframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/' + video_id + '?autoplay=1&rel=0" allowfullscreen></iframe>';
        videoContainer.insertAdjacentHTML('afterbegin', iframe)
      }
  
      btnVideoPlay.click(function() {
        let video_id = this.dataset.video
        body.classList.add('video-playing')
        embed(video_id)
		app.playPauseVideo(carousel,'pause',carousel)
		app.sliderAutoplay(carousel,true)
      })
  
      btnVideoClose.click(function() {
        body.classList.remove('video-playing')
        videoContainer.getElementsByTagName('iframe')[0].remove()
	  	app.playPauseVideo(carousel,'play',carousel)
		app.sliderAutoplay(carousel)
      })
    },
	pushDataLayer : function () {
		$('[data-layer]').click(function() {
			let event = $(this).data('layer');
			let push = {};
			let obj = $(this);
			switch (event) {
				case 'click_social':
					push = {
						'event' : event,
						'social_network' : obj.data('social')
					}
					break;
			}
			if(Object.keys(push).length > 0 && typeof dataLayer !== 'undefined') {
				dataLayer.push(push);
			}
		})
		if(parseInt($.cookie(keyAnalyticCookies)) === 1 && typeof dataLayer !== 'undefined') {
			$('.article-search-nav').find('a').click(function (e) {
				e.preventDefault();
				let obj = $(this);
				dataLayer.push({
					'event': 'filter_stories',
					'filter_selection_topic': obj.data('es')
				});
				location.href = obj.attr('href');
			})
		}
	},
	searcher : function () {
		let body = $('body'),
			btnSearch = $('#btn-search'),
			modalSearcher = $('.modal-searcher'),
			modalCloser = modalSearcher.find('.close');

		btnSearch.click(function (){
			modalSearcher.removeClass('d-none').addClass('d-flex');
			body.css({overflowY:'hidden'});
		});

		modalCloser.click(function (){
			modalSearcher.addClass('d-none').removeClass('d-flex');
			body.css({overflowY:'auto'});
		})
	},
	socialLink : function () {
		$('.link__social').click(function (e) {
			e.preventDefault();
            let href = $(this).attr('href'),
				wh = app.getWindowSize().height,
				ww = app.getWindowSize().width,
				h = Math.min(600, .6 * wh),
				w = Math.min(800, .8 * ww);
			window.open(href,'',["height=" + h, "left=" + ((ww - w) / 2), "top=" + ((wh - h) / 2), "width=" + w, 'status=1', 'toolbar=0'].join(','))
		})
	},
	copyLink : function () {
		$('.copy-link').click(function (e){
			e.preventDefault();
			if (navigator.clipboard && window.isSecureContext) {
				navigator.clipboard.writeText(window.location.href);
			} else {
                const textArea = document.createElement("textarea");
                textArea.value = window.location.href;

                // Move textarea out of the viewport so it's not visible
                textArea.style.position = "absolute";
                textArea.style.left = "-999999px";

                document.body.prepend(textArea);
                textArea.select();

                try {
                    document.execCommand('copy');
                } catch (error) {
                    console.error(error);
                } finally {
                    textArea.remove();
                }
			}
			alert($('#msg-copied').val());
		})
	},
	getWindowSize : function () {
		let body = document.body,
			documentElement = document.documentElement,
			innerHeight = window.innerHeight,
			innerWidth = window.innerWidth;
		return {
			height: innerHeight || documentElement.clientHeight || body.clientHeight,
			width: innerWidth || documentElement.clientWidth || body.clientWidth
		};
	},
	carouselRelatedArticles : function () {
		let container = $('.article-related__container'),
			carousel = container.find('.row');

		if($(window).width() < 576) {
			carousel.draggableScroll({
				scrollY: false
			})
		}
	},
	cookiebotAcept : function (){
		$('.btn-cookiebot-accept').click(function (e){
			e.preventDefault();
			let preferences = $(this).data('preferences'),
				statistics = $(this).data('statistics'),
				marketing = $(this).data('marketing'),
				cookiesPreferences = Cookiebot.consent.preferences,
				cookiesStatistics = Cookiebot.consent.statistics,
				cookiesMarketing = Cookiebot.consent.marketing;

			if(preferences !== '-') cookiesPreferences = (parseInt(preferences) === 1);
			if(statistics !== '-') cookiesStatistics = (parseInt(statistics) === 1);
			if(marketing !== '-') cookiesMarketing = (parseInt(marketing) === 1);

			Cookiebot.submitCustomConsent(cookiesPreferences,cookiesStatistics,cookiesMarketing);

			setTimeout(function (){
				location.reload()
			},500);
		})
	}
	};
	$(function(){
		app.init()
	});
})(jQuery);